import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Tooltip,
  Card,
  Grid,
  Container,
  Typography,
  FormLabel,
  FormControlLabel,
  Switch,
} from '@mui/material';
import QuestionList from './Question/QuestionList';
import Iconify from '../../iconify';
import { useFormContext } from '../../../hooks/useForm';
import SelectInput from '../../FormInputs/SelectInput';
import TextInput from '../../FormInputs/TextInput';
import { getUsersByDepartment } from '../../../services/user.service';
import CheckboxInput from '../../FormInputs/CheckboxInput';
import debounce from '../../../utils/debounce';

FormSection.propTypes = {
  section: PropTypes.object,
  addSection: PropTypes.func,
  index: PropTypes.number,
  updateSectionQuestions: PropTypes.func,
  setFocusedSection: PropTypes.func,
  focusedSection: PropTypes.string,
  sectionLength: PropTypes.number,
  selectedDepartment: PropTypes.number,
};

const iconButtonStyle = {
  color: 'white',
  transition: 'color 0.3s', // Add a smooth color transition
  '&:hover': {
    color: (theme) => theme.palette.grey[500], // Change to the desired hover color
  },
};

export default function FormSection({
  section,
  index,
  setFocusedSection,
  focusedSection,
  sectionLength,
  selectedDepartment,
}) {
  const { deleteSection, addSection, addQuestion, modifySection, getOrderedSections } = useFormContext();
  const [currentSection] = useState(section);
  const [expanded, setExpanded] = useState(true);
  const [sectionList, setSectionList] = useState();
  const [nextSection, setNextSection] = useState(section?.nextSection);
  const [priority, setPriority] = useState(section?.priority || 'sel');
  const [category, setCategory] = useState(section?.category || '');
  const [duration, setDuration] = useState(section?.duration || { days: 0, hours: 1, minutes: 0 });
  const [sectionName, setSectionName] = useState(section.name || '');
  const [agents, setAgents] = useState(section?.agents);
  const [autoAssign, setAutoAssign] = useState(section?.autoAssign ?? false);
  const { t } = useTranslation();

  const filteredAgents = useMemo(
    () =>
      agents
        ?.filter((agent) => agent.appUserRole === 'ROLE_AGENT')
        .map((agent) => ({ text: agent.username, value: agent.id })),
    [agents]
  );

  const defaultfilteredAgents = useMemo(
    () => (filteredAgents ? filteredAgents?.map((f) => f.value) : []),
    [filteredAgents]
  );

  const handlePrioritySeceltion = (subPriority) => {
    setPriority(subPriority);
    modifySection(section.id, { ...section, priority: subPriority });
  };
  const handleCategoryChange = (cat) => {
    setCategory(cat);
    modifySection(section.id, { ...section, category: cat });
  };
  const handleDurationChange = (duration) => {
    setDuration(duration);
    modifySection(section.id, { ...section, duration });
  };
  const handleSectionNameChange = (name) => {
    setSectionName(name);
    modifySection(section.id, { ...section, name });
  };
  const handleAutoAssignToggle = () => {
    const newAutoAssign = !autoAssign;
    modifySection(section.id, { ...section, autoAssign: newAutoAssign });
    setAutoAssign(newAutoAssign);
  };

  const setFineTunedDuration = (type, val) => {
    const value = val ?? 0;
    const updatedDuration = { ...duration, [type]: value };
    setDuration(updatedDuration);
    handleDurationChange(updatedDuration);
  };

  useEffect(() => {
    if (expanded === true) {
      setFocusedSection(section.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);
  useEffect(() => {
    if (focusedSection === section.id) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedSection]);

  useEffect(() => {
    if (selectedDepartment && section.nextSection === 'submit') {
      getUsersByDepartment(selectedDepartment).then((res) => {
        setAgents(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment, section.nextSection]);

  useEffect(() => {
    const sections = getOrderedSections()
      .filter((sectionItem) => sectionItem.id !== section.id)
      .map((section) => ({ text: `${t('pages.formPage.section')} ${section.name}`, value: section.id }));
    sections.push({ text: t('buttons.submit'), value: 'submit' });
    setSectionList(sections);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderedSections]);

  // Sets selected section
  const handleNextSectionSelection = (nextSectionID) => {
    setNextSection(nextSectionID);
    modifySection(section.id, { ...section, nextSection: nextSectionID });
  };

  const handleAddSection = () => {
    debounce(addSection({ name: sectionLength + 1, questions: [], isFirstSection: false }), 500);
  };
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // Set selected agents
  const handleAgentSelection = (agents) => {
    modifySection(section.id, { ...section, agents, autoAssign });
  };

  return (
    <Grid container mt={2}>
      <Grid item xs={11}>
        <Accordion expanded={expanded} onChange={toggleExpanded}>
          <AccordionSummary
            expandIcon={<Iconify icon="ooui:expand" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ backgroundColor: 'aliceblue' }}
          >
            <TextInput
              defaultValue={sectionName || index}
              label={t('pages.formPage.sectionName')}
              onBlur={(e) => handleSectionNameChange(e.target.value)}
            />
          </AccordionSummary>

          {expanded && (
            <AccordionDetails>
              <QuestionList {...section} />
              {section.nextSection === 'submit' && (
                <>
                  <SelectInput
                    options={[
                      { text: t('formControl.selectPriority'), value: 'sel' },
                      { text: t('priority.HIGH'), value: 'HIGH' },
                      { text: t('priority.MEDIUM'), value: 'MEDIUM' },
                      { text: t('priority.LOW'), value: 'LOW' },
                    ]}
                    variant="standard"
                    sx={{ width: '100%', marginTop: '2vh', marginBottom: '2vh' }}
                    setSelectedInput={handlePrioritySeceltion}
                    defaultValue={priority}
                    labelValue={t('attributes.priority')}
                  />

                  <FormControlLabel
                    sx={{ my: '1.5vh', width: '100%' }}
                    label="Auto-assign"
                    onChange={handleAutoAssignToggle}
                    value={autoAssign}
                    checked={autoAssign}
                    control={<Switch name="toggleAutoAssign" label="Auto-assign" />}
                  />
                  {filteredAgents && autoAssign ? (
                    <>
                      <FormLabel component="legend">{t('pages.formPage.assignResponsibility')}</FormLabel>
                      <CheckboxInput
                        options={filteredAgents}
                        variant="standard"
                        sx={{ width: '100%', marginTop: '2vh' }}
                        defaultValue={currentSection?.agents ? currentSection.agents : defaultfilteredAgents || []}
                        setSelectedVals={handleAgentSelection}
                        label={t('attributes.agents')}
                      />
                    </>
                  ) : null}
                  <TextInput
                    inputProps={{
                      type: 'number',
                      pattern: '[0-9]*',
                      min: '0',
                    }}
                    defaultValue={duration.days || 0}
                    label={`${t('pages.formPage.duration')} ${t('pages.formPage.inDays')}`}
                    sx={{ marginTop: '2vh', marginLeft: '1vh' }}
                    onBlur={(e) => setFineTunedDuration('days', e.target.value)}
                  />
                  <TextInput
                    inputProps={{
                      type: 'number',
                      pattern: '[0-9]*',
                      min: '0',
                    }}
                    defaultValue={duration.hours || 0}
                    label={`${t('pages.formPage.duration')} ${t('pages.formPage.inHours')}`}
                    sx={{ marginTop: '2vh', marginLeft: '1vh' }}
                    onBlur={(e) => setFineTunedDuration('hours', e.target.value)}
                  />
                  <TextInput
                    inputProps={{
                      type: 'number',
                      pattern: '[0-9]*',
                      min: '0',
                    }}
                    defaultValue={duration.minutes || 0}
                    label={`${t('pages.formPage.duration')} ${t('pages.formPage.inMinutes')}`}
                    sx={{ marginTop: '2vh', marginLeft: '1vh' }}
                    onBlur={(e) => setFineTunedDuration('minutes', e.target.value)}
                  />
                  <TextInput
                    defaultValue={category}
                    fullWidth
                    label={t('attributes.category')}
                    sx={{ marginTop: '2vh', marginLeft: '1vh' }}
                    onBlur={(e) => handleCategoryChange(e.target.value)}
                  />
                </>
              )}
            </AccordionDetails>
          )}
          <AccordionActions sx={{ backgroundColor: 'aliceblue' }}>
            <Grid container>
              <Grid item xs={4}>
                <Tooltip title={t('pages.formPage.addSection')}>
                  <IconButton onClick={handleAddSection}>
                    <Iconify
                      icon="typcn:plus"
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                        transition: 'color 0.3s',
                        '&:hover': {
                          color: (theme) => theme.palette.primary.main,
                        },
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('buttons.delete')}>
                  <IconButton onClick={() => deleteSection(section.id)}>
                    <Iconify icon="eva:trash-2-fill" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography noWrap>
                  {t('pages.formPage.afterSection')} <br />
                  <Tooltip title={section.name}>
                    <b>{section.name}</b>:
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  options={sectionList || [{ value: section.nextSection } || '']}
                  variant="standard"
                  sx={{ width: '100%' }}
                  setSelectedInput={handleNextSectionSelection}
                  defaultValue={nextSection || ''}
                  labelValue={t('pages.formPage.continueTo')}
                />
              </Grid>
            </Grid>
          </AccordionActions>
        </Accordion>
      </Grid>
      {expanded && focusedSection === section.id && (
        <Grid item xs={1}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              position: 'relative',
            }}
          >
            <Card
              color="primary"
              aria-label="add"
              variant="extended"
              size="small"
              direction="down"
              raised
              sx={{
                top: '50vh',
                opacity: '0.9',
                textAlign: 'center',
                position: 'sticky',
                display: 'inline-grid',
                bgcolor: '#2065D1',
                overflow: 'hidden',
              }}
            >
              <Tooltip title={t('pages.formPage.addQuestion')}>
                <IconButton
                  onClick={() => {
                    addQuestion(section.id, {});
                  }}
                >
                  <Iconify icon="simple-line-icons:plus" sx={iconButtonStyle} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('pages.formPage.addSection')}>
                <IconButton onClick={handleAddSection}>
                  <Iconify icon="bi:sign-intersection" sx={iconButtonStyle} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('pages.formPage.deleteSection')}>
                <IconButton onClick={() => deleteSection(section.id)}>
                  <Iconify icon="eva:trash-2-fill" sx={iconButtonStyle} />
                </IconButton>
              </Tooltip>
            </Card>
          </Container>
        </Grid>
      )}
    </Grid>
  );
}
