import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import FormCard from '../formCard/FormCard';

// ----------------------------------------------------------------------

FormList.propTypes = {
  forms: PropTypes.array,
  removeForm: PropTypes.func,
  setSelectedForm: PropTypes.func,
  setOpenDetailedView: PropTypes.func,
};

export default function FormList({ forms, removeForm, setSelectedForm, setOpenDetailedView }) {
  return (
    <Grid container spacing={6}>
      {forms?.map((form, index) => (
        <Grid key={index} item xs={12} sm={4} md={3}>
          <FormCard
            form={form}
            removeForm={removeForm}
            setSelectedForm={setSelectedForm}
            setOpenDetailedView={setOpenDetailedView}
          />
        </Grid>
      ))}
    </Grid>
  );
}
