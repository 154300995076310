import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Stack,
  Select,
  MenuItem,
  Backdrop,
  Container,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  FormHelperText,
  CircularProgress,
} from '@mui/material';

import FormPreview from '../Form/FormPreview';
import { FormProvider } from '../../hooks/useForm';
import { createTicket } from '../../services/ticket.service';
import { fetchFormsByDepartment } from '../../services/form.service';

export default function TicketForm({ departments, favoriteForm }) {
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    getValues,
    setError,
  } = useForm({
    mode: 'all',
    defaultValues: {
      title: '',
    },
  });
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [forms, setForms] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedForm, setSelectedForm] = useState();
  const [progress, setProgress] = useState(0);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [useFavorite, setUseFavorite] = useState(true);

  const { t } = useTranslation();

  const handleDepartmentSelection = (e) => {
    setSelectedForm();
    setUseFavorite(false);
    getFormsByDepartment(e.target.value);
    setSelectedDepartment(e.target.value);
  };

  const handleBackdropClose = () => {
    setBackdropOpen(false);
  };

  const handleBackdropOpen = () => {
    setBackdropOpen(true);
  };

  const addTicket = async (
    userId,
    data,
    priority,
    duration,
    category,
    files,
    description,
    department,
    agents,
    autoAssign
  ) => {
    const title = getValues('title');
    const ticketRequest = {
      title,
      description,
      status: 'UNASSIGNED',
      status_id: 1,
      priority: priority || 'LOW',
      estimatedDuration: duration,
      category,
      createdBy: userId,
      department_id: department,
      data,
      agents,
      autoAssign,
      form_id: selectedForm.id,
    };
    const formData = files;
    const ticketData = new Blob([JSON.stringify(ticketRequest, null, 2)], {
      type: 'application/json',
    });
    formData.append('ticketRequest', ticketData);
    const onUploadProgress = (progressEvent) => {
      const uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      // Update your UI or state with the progress value
      setProgress(uploadProgress);
    };
    return createTicket(formData, onUploadProgress);
  };

  const getFormsByDepartment = async (deptId) => {
    const fromsData = await fetchFormsByDepartment(deptId);
    setForms(fromsData);
  };

  const handleNext = () => {
    setShowForm(true);
  };

  const handleCancel = () => {
    if (showForm) setShowForm(false);
    else navigate('/dashboard/tickets');
  };

  const handleFormSelection = (e) => {
    setSelectedForm(forms.find((form) => form.id === e.target.value));
  };

  useEffect(() => {
    setError('title', {
      type: 'manual',
      message: t('formControl.enterTitle'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (favoriteForm) {
      getFormsByDepartment(favoriteForm.departmentId);
      setSelectedDepartment(favoriteForm.departmentId);
      setShowForm(true);
    }
  }, [favoriteForm]);

  useEffect(() => {
    if (favoriteForm && selectedDepartment && useFavorite) {
      setSelectedForm(favoriteForm);
    }
  }, [favoriteForm, selectedDepartment, useFavorite]);

  return (
    <Container>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropOpen}>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" value={progress} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="white">
              {`${progress}%`}
            </Typography>
          </Box>
        </Box>
      </Backdrop>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          {t('pages.ticketDetails.createTicket')}
        </Typography>
      </Stack>

      <form onSubmit={handleSubmit(() => {})}>
        <Stack spacing={3}>
          <br />
          <FormControl fullWidth error={!!errors.department}>
            <InputLabel id="demo-simple-select-label">{t('attributes.department')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="department-select"
              disabled={showForm}
              label={t('attributes.department')}
              defaultValue=""
              onChange={handleDepartmentSelection}
              value={selectedDepartment || ''}
            >
              {departments?.map((department) => {
                const { id, name } = department;

                return (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>{errors.department?.message}</FormHelperText>
          </FormControl>

          {showForm ? (
            <>
              <FormControl fullWidth>
                <InputLabel id="formselect-label">{t('nav.forms')}</InputLabel>

                <Select
                  labelId="form-label"
                  id="form-select"
                  label={t('nav.forms')}
                  value={selectedForm?.id || ''}
                  onChange={handleFormSelection}
                >
                  {forms?.map((form) => {
                    const { id, title } = form;

                    return (
                      <MenuItem key={id} value={id}>
                        {title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              {selectedForm?.formData && (
                <>
                  <TextField
                    id="title"
                    name="title"
                    label={t('attributes.title')}
                    error={!!errors.title}
                    helperText={errors.title?.message || ''}
                    {...register('title', {
                      required: t('formControl.enterTitle'),
                      maxLength: {
                        value: 50,
                        message: 'the title must have at most 50 characters',
                      },
                    })}
                  />

                  <FormProvider key={selectedForm.id} initialData={selectedForm.formData}>
                    <FormPreview
                      disabled={!isValid}
                      addTicket={addTicket}
                      handleBackdropClose={handleBackdropClose}
                      handleBackdropOpen={handleBackdropOpen}
                    />
                  </FormProvider>
                </>
              )}
            </>
          ) : null}
          <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Grid>
              <LoadingButton
                id="next-btn"
                variant="contained"
                onClick={handleNext}
                disabled={!selectedDepartment || showForm}
              >
                {t('buttons.next')}
              </LoadingButton>
              <LoadingButton
                id="cancel-btn"
                sx={{ my: 2, bgcolor: '#b90e0a', marginLeft: '1vh' }}
                variant="contained"
                onClick={handleCancel}
              >
                {t('buttons.cancel')}
              </LoadingButton>
            </Grid>
          </Stack>
        </Stack>
      </form>
    </Container>
  );
}

TicketForm.propTypes = {
  departments: PropTypes.array,
  favoriteForm: PropTypes.object,
};
